<script setup>
import { computed, defineEmits, defineProps } from 'vue';
import { useProfileActions } from '@profile';
import { PopUpManager as PopUp } from '@/Ship';

const props = defineProps({comment: {
		type: Object,
		required: true
	}
});
const emits = defineEmits(['delete', 'restore']);

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const author = computed(() => {
	return props.comment.user && props.comment.user.name
		? props.comment.user.name
		: 'n/a'
});
</script>

<template>
    <fieldset v-if="!comment.deleted_at || profile.can('Comment RemovedView')" class="comment"
              v-bind:class="{deleted: comment.deleted_at}">
        <legend class="author">
            <g-symbol v-if="comment.deleted_at" name="delete" width="18" height="18"/>
            <g-symbol v-else v-bind:name="'comments'" width="20" height="20"/>
            {{ author }}
        </legend>
        <g-flex class="actionbar" align="center">
            <button v-if="!comment.deleted_at && profile.can('Comment Edit')"
                    class="edit-btn with-tooltip"
                    v-bind:data-tooltip="$t('lead.edit_comment', 'Edit comment')"
                    v-on:click="
                    PopUp.open(require('@comment/components/PopUps/EditComment.vue'))
                    .setCaption($t('comments', 'Edit Comment')).setPayload({payload:{id: comment.id}})">
                <g-symbol name="edit" width="18" height="18"/>
            </button>
            <button v-if="comment.deleted_at && profile.can('Comment Restore')"
                    class="restore-btn with-tooltip"
                    v-bind:data-tooltip="$t('lead.restore_comment', 'Restore comment')"
                    v-on:click="$emit('restore')">
                <g-symbol name="restore" width="24" height="24"/>
            </button>
            <button v-else-if="profile.can('Comment Remove')"
                    class="delete-btn with-tooltip"
                    v-bind:data-tooltip="$t('lead.delete_comment', 'Delete comment')"
                    v-on:click="$emit('delete')">
                <g-symbol name="close-bg" width="24" height="24"/>
            </button>
        </g-flex>

        <slot/>

        <span class="time">{{ comment.created_at }} <span v-if="comment.deleted_at" class="danger"> / {{ comment.deleted_at }}</span></span>
    </fieldset>
</template>

<style lang="scss" scoped>
.comment {
    margin: 20px 10px;
    padding: 5px 0;
    position: relative;
    color: var(--env-text-color, $env-text-color);

    & > .author {
        padding: 0 5px;
        display: flex;
        gap: 4px;
        align-items: center;
    }

    .time {
        float: right;
        margin-left: 20px;
        color: var(--success, $success);
    }

    &:not(.deleted) {
        border-top: 1px solid var(--separator-color, $separator-color);

        & > .author {
            color: var(--secondary, $secondary);

            & > .g-symbol {
                fill: var(--env-text-color, $env-text-color);
            }
        }
    }

    &.deleted {
        border-top: 1px solid var(--danger, $danger);

        & > .author {
            color: var(--danger-hover, $danger-hover);

            & > .g-symbol {
                fill: var(--danger, $danger);
            }
        }
    }
}

.actionbar {
    padding-left: 4px;
    font-size: 0;
    top: -24px;
    right: 0;
    position: absolute;
    background-color: var(--env-bg, $env-bg);
    border-radius: var(--bar-rounding, $bar-rounding);
}

button {
    position: relative;
    cursor: pointer;
    background-color: transparent;
    fill: var(--env-text-color, $env-text-color);

    &:hover {
        fill: var(--danger-hover, $danger-hover);

        &::after {
            background-color: var(--danger, $danger);
        }

        &::before {
            border-bottom-color: var(--danger, $danger);
        }
    }

    &::after {
        left: 50%;
        transform: translateX(-50%);
        top: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
    }

    &::before {
        transform: translateX(-50%);
        left: 50%;
        top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
    }
}

.restore-btn:hover {
    fill: var(--success-hover, $success-hover);

    &::after {
        background-color: var(--success, $success);
    }

    &::before {
        border-bottom-color: var(--success, $success);
    }
}

.edit-btn:hover {
    fill: var(--warning, $warning);

    &::after {
        background-color: var(--warning, $warning);
    }

    &::before {
        border-bottom-color: var(--warning, $warning);
    }
}
</style>
