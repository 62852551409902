<script setup>

import {ref, computed, onMounted} from 'vue';
import { useI18n } from 'vue-i18n';
import { useEnvComponents } from '@overlay';
import { useProfileActions } from '@profile';
import { STATE } from "@/Ship";

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const AccountMenu = useEnvComponents('AccountMenu');

const { locale } = useI18n();
const language = ref(locale);

function saveState(key, value) {
	if (!profile.state.env) {
		profile.state.env = {};
	}
	profile.state.env[key] = value;
	profile.saveState();
}

const currentTime = ref();
setInterval(() => {
    const now = new Date();
    currentTime.value = [
        now.getUTCHours().toString().padStart(2, '0'),
        now.getUTCMinutes().toString().padStart(2, '0'),
        now.getUTCSeconds().toString().padStart(2, '0')
    ].join(':')
}, 1000);

const mode = computed({
    get: () => (profile.state.env?.mode || STATE.MODE) === 'dark',
    set: value => {
        const theme = value ? 'dark' : 'light'

        document.documentElement.setAttribute('theme', theme);

        profile.state.env.mode = theme;
        profile.saveState();
        return true;
    }
});

document.documentElement.setAttribute('theme', profile.state.env?.mode || STATE.MODE);
</script>

<template>
    <div class="topbar">
        <div class="toolbar">
            <slot/>
            <account-menu/>
        </div>
        <div class="separator"></div>

        <g-lang-select v-model:language="language" v-on:selectedLang="saveState('language', $event)"/>

        <g-flex inline class="mode" align="center" gap="4">
            <g-switch v-model:checked="mode"/>
            <g-symbol v-bind:name="mode ? 'moon' : 'sun'" width="20" height="20"/>
        </g-flex>

        <router-link class="clock" v-bind:to="{ name: 'Welcome', params: $route.params }">
            <g-symbol name="time" width="22" height="22"/>
            <span class="time">{{ currentTime }}</span>
        </router-link>
    </div>

</template>

<style lang="scss" scoped>
.topbar {
    z-index: 6;
    grid-area: tbr;
    width: 100%;
    padding: 0 10px;
    gap: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: var(--bar-height, $bar-height);
    background-color: var(--body-bg, $body-bg);
    box-shadow: 0 10px 10px -12px #00000055;

    & > .toolbar {
        gap: 10px;
        display: flex;
        align-items: center;
    }

    .clock {
        width: 120px;
        font-size: 20px;
        font-weight: bold;
        font-family: $font-time;
        fill: var(--secondary-hover, $secondary-hover);
        color: var(--secondary-hover, $secondary-hover);
        //color: var(--title-text-color, $title-text-color);
        gap: 8px;
        display: flex;
        align-items: center;

        & > .time {
            color: var(--title-text-color, $title-text-color);
        }

        svg {
            flex-shrink: 0;
        }
    }
}

.separator {
    flex-grow: 1;
}

.mode {
    fill: #ffe090;
}
</style>