<script setup>

import { useLeadActions } from '@lead';
import { useTradingActions } from '@trading';
import { i18n, PopUpManager as PopUp } from '@/Ship';
import { useRouter } from 'vue-router';
import { computed } from 'vue';

const {
    getPositionHistoryResource,
    restorePosition
} = useTradingActions();
const getLeadResource = useLeadActions('getLeadResource');

const leads = getLeadResource();

const positions = getPositionHistoryResource(
	{customer_id: [leads.model.customer_id]},
	true,
	'PositionsHistory Component'
);

const router = useRouter();

function restore(id) {
    positions.index = positions.state.findIndex(position => position.id === id);

    const { pl } = positions.state[positions.index];
    const { usdt_amount } = positions.state[positions.index];

    const refund = Math.abs(parseInt(usdt_amount, 10) + Number(pl));

    PopUp.open(require('@/Ship/components/PopUps/Confirm'))
    .setCaption(i18n.global.t('trading.restore_history', 'Restore position'))
    .setMessage(`
        <div class="message">
            <p><b>${i18n.global.t('trading.history_restore_confirm', { id })}!</b></p>
            <p><b class="${+usdt_amount + pl < 0 ? 'success' : 'danger'}">
                ${(usdt_amount + pl < 0
        ? i18n.global.t('trading.returned_to_balance', { amount: Math.round(refund) })
        : i18n.global.t('trading.deducted_from_balance', { amount: Math.round(refund) })) }
            </b></p>
        </div>
    `).setAction(() => {
        const index = positions.state.findIndex(item => item.id === id);

        if (index > -1) {
            restorePosition(positions.state[positions.index].id).then(() => {
                if (positions.index === index) {
                    positions.index = null;
                }
                positions.collection.value.splice(index, 1);
                PopUp.close();
            })
        }
    })
    .setPayload({
        payload: {
            icon: 'refresh',
            fill: 'success',
            text: 'restore'
        }
    });
}

function editPosition(id) {
    positions.index = positions.state.findIndex(position => position.id === id);

    PopUp.open(require('@trading/components/PopUps/EditClosedPosition'))
    .setCaption(i18n.global.t('trading.edit_history', 'Edit an Closed position'))
    .setPayload({
        payload: positions.model
    });
}

function removePosition(id) {
    const index = positions.state.findIndex(position => position.id === id);

    PopUp.open(require('@/Ship/components/PopUps/Confirm'))
    .setCaption(i18n.global.t('trading.remove_position', 'Remove Position'))
    .setMessage(`
            <div class="message">
                <p><b>${i18n.global.t('trading.history_remove_confirm', { id })}!</b></p>
                <p>${(
        positions.state[index].pl < 0
            ? i18n.global.t('trading.returned_to_balance', { amount: positions.state[index].pl * -1 })
            : i18n.global.t('trading.deducted_from_balance', { amount: positions.state[index].pl })
    )}
                </p>
            </div>
        `)
    .setAction(() => {
        const index = positions.state.findIndex(item => item.id === id);

        if (index > -1) {
            positions.delete(id).then(() => {
                if (positions.index === index) {
                    positions.index = null;
                }
                positions.collection.value.splice(index, 1);
            });
        }
        //leads.load();
        PopUp.close();
    });
}
</script>

<template>
    <div class="history">

        <g-preloader-overlay v-if="positions.isLoading"/>

        <message/>
        <div v-for="position in positions.state" v-bind:key="`position-${position.id}`" v-bind:data-id="position.id" class="card">
            <div class="caption">
                <div class="side">
                    &nbsp;ID: <span class="value">{{ position.id }} </span> |
                    <span v-if="position.side === 'Long'" class="long">Long ↗</span>
                    <span v-else class="short">Short ↘</span>
                </div>
                <div class="pair value">
                    <span class="market" v-bind:class="position.pair.market">{{ position.pair.market }}</span>
                    <span v-bind:class="position.pair.base.toLowerCase()">{{ position.pair.base }}</span>:<span
                    v-bind:class="position.pair.counter.toLowerCase()">{{ position.pair.counter }}</span>
                </div>
            </div>
            <div class="actions">
                <button class="action restore-btn"
                        type="button"
                        v-on:click="restore(position.id)">
                    <g-symbol name="refresh" width="16" height="16"/>
                    Restore
                </button>
                <button class="action edit-btn" v-on:click="editPosition(position.id)">
                    <g-symbol name="edit" width="16" height="16"/>
                    Edit
                </button>
                <button class="action delete-btn">
                    <g-symbol name="delete" width="16" height="16" v-on:click="removePosition(position.id)"/>
                </button>
            </div>
            <div></div>
            <!-- -->
            <div>
                <template v-if="position.pair.counter !== 'USDT'">
                    <div class="title">Amount:</div>
                    <span class="value">
						{{ Number(position.usdt_amount) }} <span class="usdt">USDT</span>
					</span>
                </template>
            </div>
            <!-- -->
            <div class="status">
                <div class="title">Status:</div>
                <spam class="value">{{ position.status }}</spam>
            </div>
            <div class="date">
                <div class="title">Opened at:</div>
                <span class="value">{{ position.created_at }}</span>
            </div>

            <div class="price">
                <div class="title">Entry Price:</div>
                <span class="value">
					{{ Number(position.entry_price) }}
					<small class="counter" v-bind:class="position.pair.counter.toLowerCase()">{{ position.pair.counter }}</small>
				</span>
            </div>

            <div class="amount">
                <div class="title">Quantity:</div>
                <span class="value">
                    {{ Number(position.amount) }}
                    <span class="counter" v-bind:class="position.pair.counter.toLowerCase()">
                        {{ position.pair.counter }}
                    </span>
                </span>
            </div>

            <div class="leverage">
                <div class="title">Leverage, Total</div>
                <span class="value">
					⨯{{ position.leverage }} = {{ Number(position.total_amount) }}
					<small class="counter" v-bind:class="position.pair.counter.toLowerCase()">{{ position.pair.counter }}</small>
				</span>
            </div>
            <div class="date">
                <div class="title">Closed at:</div>
                <span class="value">{{ position.deleted_at }}</span>
            </div>

            <div class="price">
                <div class="title">Close Price:</div>
                <span class="value">
					{{ Number(position.close_price) }}
					<small class="counter" v-bind:class="position.pair.counter.toLowerCase()">{{ position.pair.counter }}</small>
				</span>
            </div>
            <div>
                <div class="title">Value:</div>
                <span class="value">
					{{ Number(position.value) }}
					<small class="counter" v-bind:class="position.pair.base.toLowerCase()">{{ position.pair.base }}</small>
				</span>
            </div>
            <div class="pl">
                <div class="title">Profit & Loss:</div>
                <span class="value">
					<span v-bind:class="position.pl > 0 ? 'long' : 'short'">{{ position.pl }}</span>
				</span>
            </div>
            <div class="take-profit" v-bind:class="{unset: !position.take_profit}">
                <div class="title">Take Profit / Stop Loss:</div>
                <span class="value">
					<span>{{ Number(position.take_profit) || 'UnSet' }}</span> / <span
                    class="short">{{ Number(position.stop_loss) || 'UnSet' }}</span>
				</span>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.history {
    padding: 0 50px 0 60px;

    & > .card {
        display: grid;
        grid-gap: 10px;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 150px;
        margin: 10px 0;
        padding: 48px 22px 22px;
        position: relative;
        color: var(--env-text-color, $env-text-color);
        border-radius: var(--bar-rounding, $bar-rounding);
        border: 1px solid var(--env-text-color, $env-text-color);
        //background-color: var(--bar-bg, $bar-bg);
        //box-shadow: 0 5px 15px -5px #000000aa;

        .title {
            font-size: 0.9rem;
            color: var(--label-color, $label-color);
        }

        .value {
            white-space: nowrap;
        }

        .caption {
            top: 8px;
            left: 12px;
            position: absolute;
        }

        & > .actions {
            top: 8px;
            right: 5px;
            position: absolute;

            & > .action {
                display: inline-flex;
                align-items: center;
                gap: 4px;
                height: 28px;
                padding: 0 7px;
                margin-right: 5px;
                background-color: transparent;
                fill: var(--env-text-color, $env-text-color);
                border-radius: var(--btn-rounding, $btn-rounding);
                color: var(--env-text-color, $env-text-color);
                border: 1px solid var(--env-text-color, $env-text-color);

                &.restore-btn:hover {
                    background-color: var(--primary, $primary);
                }

                &.edit-btn:hover {
                    background-color: var(--success, $success);
                }

                &.delete-btn {
                    fill: var(--danger-hover, $danger-hover);
                    border: 1px solid var(--danger, $danger);

                    &:hover {
                        background-color: var(--danger, $danger);
                    }
                }

                &:hover {
                    fill: white;
                    color: white;
                    border-color: transparent;
                }
            }
        }

        .side {
            font-weight: bold;
        }

        .market {
            font-size: 0.9rem;
            padding: 1px 5px;
            border-radius: 5px;
            display: inline-block;
            margin-right: 4px;
            text-transform: capitalize;

            &.crypto {
                background-color: var(--primary, $primary);
                color: var(--btn-primary-color, $btn-primary-color);
            }

            &.stocks {
                background-color: var(--danger, $danger);
                color: var(--btn-primary-color, $btn-primary-color);
            }

            &.commodities {
                background-color: var(--success, $success);
                color: var(--btn-primary-color, $btn-primary-color);
            }
        }

        .status {
            color: var(--warning-hover, $warning-hover);
        }
    }
}

.counter {
    font-size: 0.8rem;
}

.usdt_amount {
    color: var(--main-text-color, $title-color);
}

.btc {
    color: $btc;
}

.usdt {
    color: $usdt;
}

.eth {
    color: $eth;
}
</style>