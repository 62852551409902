
import { STACK, ApiClient, useShipResources } from '@/Ship';

const { BaseResource } = useShipResources();

export default class Comment extends BaseResource {

    static #instance = null;

    endpoint = 'comments';
    referer = 'Comment Resource';

    section = 'Leads';
    container = 'Comments';

    static getInstance() {
        if (!Comment.#instance) {
            Comment.#instance = new Comment();
        }
        return Comment.#instance;
    }

    restore(id) {
        STACK.push(() => ApiClient.put(`${this.endpoint}/${id}`).then(() => {
            const index = this.state.findIndex(comment => comment.id === id);

            if (index > -1) {
                this.state[index].deleted_at = null;
            }
        }));
    }
}
