<script setup>
import { reactive, computed } from 'vue';
import { useLeadActions } from '@lead';
import { useFinanceActions } from '@finance';
import { useTradingActions } from '@trading';
import { i18n, PopUpManager as PopUp } from '@/Ship';
import { useSettingsActions } from "@settings";

const prices = reactive({});

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource();

const getRates = useFinanceActions('getRates');

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const {
    plCalculate,
    getPositionResource
} = useTradingActions();
const positions = getPositionResource(
	{
		customer_id: [lead.model.customer_id],
        status: ['Open']
	},
	true,
	'Positions Component'
);
positions.onload = () => {
    positions.state.forEach(position => {
        const marketCurrency = (position.pair.market === 'forex' || position.pair.market === 'commodities') ? 'USD' : 'USDT';
        prices[position.pair_symbol] = 0;
        if (position.pair.counter !== marketCurrency && position.pair.base !== marketCurrency) {
            prices[`${position.pair.counter}${marketCurrency}`] = 0;
            prices[`${marketCurrency}${position.pair.counter}`] = 0;
        }
    });

    loadPrices(Object.keys(prices));

    positions.state.forEach((position) => {
        position.pl = computed(() => {
            return plCalculate(position, {
                crypto: lead.model.specifics?.crypto_fee || settings.general.defaultCryptoFee || 0.001,
                stocks: lead.model.specifics?.stock_fee || settings.general.defaultStockFee || 0.001,
                //forex: lead.model.specifics?.forex_fee || settings.general.defaultForexFee || 0.001,
                commodities: lead.model.specifics.commodity_fee || settings.general.defaultCommodityFee || 0.001,
            }[position.pair.market], prices)
        });
    });
}

function loadPrices(symbols) {
    getRates(symbols).then(({data}) => {
        data.forEach(quote => {
            const multiplier = (parseInt(lead.model.specifics.multipliers[quote.symbol]) || 0) / 100;
            prices[quote.symbol] = (Number(quote.price) + (quote.price * multiplier)).toFixed(5);
        });
    })
}

function refreshPrice(position) {
    const symbols = [position.pair_symbol];
    const marketCurrency = (position.pair.market === 'forex' || position.pair.market === 'commodities') ? 'USD' : 'USDT';
    if (position.pair.counter !== marketCurrency && position.pair.base !== marketCurrency) {
        symbols.push(`${position.pair.counter}${marketCurrency}`);
        symbols.push(`${marketCurrency}${position.pair.counter}`);
    }

    loadPrices(symbols);
}

function editPosition(id) {
    positions.index = positions.state.findIndex(position => position.id === id);

    PopUp.open(require('@trading/components/PopUps/EditPosition'))
        .setCaption(i18n.global.t('trading.edit_position', 'Edit Open Position'))
        .setPayload({
            payload: positions.model
        });
}

function removePosition(id) {
    const index = positions.state.findIndex(position => position.id === id);

    PopUp.open(require('@/Ship/components/PopUps/Confirm'))
        .setCaption(i18n.global.t('trading.remove_position', 'Remove Position'))
        .setMessage(`
            <div class="message">
                <p><b>${i18n.global.t('trading.remove_confirm', { id })}!</b></p>
                <p>${i18n.global.t('trading.returned_to_balance', { amount: positions.state[index].usdt_amount })}</p>
            </div>
        `)
        .setAction(() => {
            const index = positions.state.findIndex(item => item.id === id);

            if (index > -1) {
                positions.delete(id).then(() => {
                    if (positions.index === index) {
                        positions.index = null;
                    }
                    positions.collection.value.splice(index, 1);
                });
            }
            lead.load()
            PopUp.close();
        });
}

</script>

<template>
    <div class="positions">

        <g-preloader-overlay v-if="positions.isLoading"/>

        <div v-for="position in [...positions.state].reverse()" v-bind:key="`position-${position.id}`" class="card">
            <div class="caption">
                ID: <span class="value">{{ position.id }}</span> |
                <span class="market" v-bind:class="position.pair.market">{{ position.pair.market !== 'Commodities' ? position.pair.market : 'goods' }}</span>
            </div>
            <div class="actions">
                <button class="action edit-btn"
                        type="button"
                        v-on:click="editPosition(position.id)">
                    <g-symbol name="edit" width="16" height="16"/>
                    Edit
                </button>
                <!--
                <button class="action delete-btn"
                        type="button"
                        v-on:click="removePosition(position.id)">
                    <g-symbol name="delete" width="16" height="16"/>
                </button>
                -->
            </div>

            <div class="symbol">
                <span v-bind:class="position.pair.base.toLowerCase()">{{ position.pair.base }}</span>:<span v-bind:class="position.pair.counter.toLowerCase()">{{ position.pair.counter }}</span>
            </div>

            <!-- SIDE -->
            <div v-if="position.side === 'Long'" class="side long">{{ position.side }} ↗</div>
            <div v-else class="side short">{{ position.side }} ↘</div>

            <!-- MARKET PRICE -->
            <div class="market-price">
                <div class="title">Market Price:</div>
                <div class="value">
                    {{ Number(prices[position.pair_symbol]) }} <span class="currency" v-bind:class="position.pair.counter.toLowerCase()">{{ position.pair.counter }}</span>
                </div>
            </div>

            <!-- PnL -->
            <div class="pl">
                <div class="title">Profit & Loss:</div>
                <g-flex class="value" v-bind:class="(position.pl || 0) < 0 ? 'decrease' : 'increase'" gap="5" align="center">
                    {{ position.pl || 0 }} <span class="currency">USDT</span>
                    <g-symbol name="refresh"
                              width="15"
                              height="15"
                              v-on:click="refreshPrice(position)"/>
                </g-flex>
            </div>

            <!-- SWAP -->
            <div class="swap">
                <div class="title">Swap:</div>
                <span class="value">{{ Number(position.swap) }}</span>
            </div>

            <!-- AMOUNT -->
            <div>
                <template v-if="position.pair.counter !== 'USDT'">
                    <div class="title">Amount:</div>
                    <span class="value">
						{{ Number(position.usdt_amount) }} <span class="currency usdt">USDT</span>
					</span>
                </template>
            </div>

            <!-- ENTRY PRICE -->
            <div class="price">
                <div class="title">Entry Price:</div>
                <span class="value">
					{{ Number(position.entry_price) }}
					<small class="counter currency" v-bind:class="position.pair.counter.toLowerCase()">{{ position.pair.counter }}</small>
				</span>
            </div>

            <!-- OPENED AT -->
            <div class="date">
                <div class="title">Opened at:</div>
                <span class="value">{{ position.created_at }}</span>
            </div>

            <!-- VALUE -->
            <div>
                <div class="title">Value:</div>
                <span class="value">
                    {{ Number(position.value) }}
                    <small class="counter currency" v-bind:class="position.pair.base.toLowerCase()">{{ position.pair.base }}</small>
                </span>
            </div>

            <!-- USDT AMOUNT -->
            <div class="amount">
                <div class="title">Quantity:</div>
                <span class="value">{{ Number(position.amount) }} <span class="counter currency" v-bind:class="position.pair.counter.toLowerCase()">{{ position.pair.counter }}</span></span>
            </div>

            <!-- LEVERAGE -->
            <div class="leverage">
                <div class="title">Leverage, Total</div>
                <span class="value">
                    ⨯{{ position.leverage }} = {{ Number(position.total_amount) }}
                    <small class="counter currency" v-bind:class="position.pair.counter.toLowerCase()">{{ position.pair.counter }}</small>
                </span>
            </div>

            <!-- TAKE PROFIT / STOP LOSS -->
            <div class="take-profit" v-bind:class="{unset: !position.take_profit}">
                <div class="title">Take Profit / Stop Loss:</div>
                <span class="value">
                    <span>{{ Number(position.take_profit) || 'UnSet'}}</span> / <span class="short">{{ Number(position.stop_loss) || 'UnSet'}}</span>
                </span>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.positions {
    padding: 0 50px 0 60px;

    & > .card {
        display: grid;
        grid-gap: 10px;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-columns: 1fr 1fr 1fr 150px;
        margin: 6px 0;
        padding: 45px 18px 18px;
        position: relative;
        color: var(--env-text-color, $env-text-color);
        border-radius: var(--bar-rounding, $bar-rounding);
        border: 1px solid var(--env-text-color, $env-text-color);

        .title {
            font-size: 0.9rem;
            color: var(--label-color, $label-color);
        }

        .value {
            white-space: nowrap;
        }

        .caption {
            top: 8px;
            left: 12px;
            position: absolute;
        }

        & > .actions {
            top: 8px;
            right: 5px;
            position: absolute;

            & > .action {
                display: inline-flex;
                align-items: center;
                gap: 4px;
                height: 28px;
                padding: 0 7px;
                margin-right: 5px;
                background-color: transparent;
                fill: var(--env-text-color, $env-text-color);
                border-radius: var(--btn-rounding, $btn-rounding);
                color: var(--env-text-color, $env-text-color);
                border: 1px solid var(--env-text-color, $env-text-color);

                &.restore-btn:hover {
                    background-color: var(--primary, $primary);
                }

                &.edit-btn:hover {
                    background-color: var(--success, $success);
                }

                &.delete-btn {
                    fill: var(--danger-hover, $danger-hover);
                    border: 1px solid var(--danger, $danger);

                    &:hover {
                        background-color: var(--danger, $danger);
                    }
                }

                &:hover {
                    fill: white;
                    color: white;
                    border-color: transparent;
                }
            }
        }

        .symbol,
        .side {
            font-size: 1.1rem;
            font-weight: bold;
        }

        .market {
            font-size: 0.9rem;
            padding: 1px 5px;
            border-radius: 5px;
            display: inline-block;
            margin-right: 4px;
            text-transform: capitalize;

            &.crypto {
                background-color: var(--primary, $primary);
                color: var(--btn-primary-color, $btn-primary-color);
            }

            &.stocks {
                background-color: var(--danger, $danger);
            }

            &.commodities {
                background-color: var(--success, $success);
            }
        }

        .pl {
            & .g-symbol {
                cursor: pointer;
                fill: var(--secondary, $secondary);

                &:hover {
                    fill: var(--secondary-hover, $secondary-hover);
                }
            }
        }
    }
}

.long {
    color: var(--success-hover, $success-hover);
}

.short {
    color: var(--danger-hover, $danger-hover);
}

.currency {
    font-size: 80%;
    font-weight: 600;
}

.btc {
    color: $btc;
}

.usdt {
    color: $usdt;
}

.eth {
    color: $eth;
}

.increase {
    color: var(--success-hover, $success-hover);
}

.decrease {
    color: var(--danger-hover, $danger-hover);
}
</style>