<template>
    <div class="g-textarea" v-bind:class="{error, disabled, backlight, filled: !!modelValue}" v-on:keydown.stop>
        <textarea v-bind:value="modelValue"
                  v-on:input="inputEvent"
                  v-bind="$attrs"
                  v-bind:disabled="disabled"
                  v-bind:readonly="readonly"
                  v-bind:placeholder="label"
                  v-on:keydown.tab.prevent="insertTab($event.target)"/>
        <slot/>

        <g-symbol v-if="readonly"
                  name="lock"
                  width="18"
                  height="18"
                  class="btn"/>

        <g-symbol v-else-if="modelValue !== initValue"
                  name="close"
                  class="btn"
                  width="20"
                  height="20"
                  v-on:click="reset"/>

        <div v-if="error || hint" class="hint-text">{{ error || hint }}</div>
    </div>
</template>

<script>
export default {
    name: 'GTextarea',
    inheritAttrs: false,
    props: {
        label: {
            type: String
        },
        modelValue: {
            type: String
        },
        hint: {
            type: String
        },
        disabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        backlight: {
            type: Boolean,
            default: false
        },
        error: {
            type: String,
            default: ''
        },
    },
    emits: ['update:modelValue'],
    setup(props, {emit}) {

        const initValue = props.modelValue || '';

        const inputEvent = event => emit('update:modelValue', event.target.value);

        const reset = () => {
            emit('update:modelValue', initValue);
        };

        function insertTab(textarea) {
            const start = textarea.selectionStart;
            const before = props.modelValue.substring(0, start);
            const after = props.modelValue.substring(start, props.modelValue.length);
            textarea.value = before + '\t' + after;
            emit('update:modelValue', textarea.value);
            textarea.setSelectionRange(start+1,start+1);
        }

        return {
            inputEvent,
            reset,
            insertTab,
            initValue,
        };
    }
};
</script>

<style lang="scss" scoped>
.g-textarea {
    padding: 0;
    box-sizing: border-box;
    position: relative;
    min-height: var(--input-height, $input-height);
    border-radius: var(--input-rounding, $input-rounding);
    border: 1px solid var(--input-border, $input-border);
    transition: background-color 0.2s;

    &.backlight {
        border-color: var(--secondary, $secondary);
        box-shadow: inset 0 0 2px 0 var(--secondary-hover, $secondary-hover);
    }

    textarea {
        width: 100%;
        height: 100%;
        border: none;
        padding: 10px 20px 10px 10px;
        resize: vertical;
        color: inherit;
        background-color: transparent;
        font-family: var(--font-primary, $font-primary);
        font-size: var(--input-font-size, $input-font-size);

        &:focus {
            outline: none;
        }
    }

    & > .g-symbol {
        fill: var(--secondary, $secondary);
    }

    & > .hint-text {
        bottom: 85%;
        left: 50%;
        width: 100%;
        padding: 5px 15px;
        border-radius: 3px;
        position: absolute;
        transform: translateX(-50%);
        background-color: var(--hint-bg, $hint-bg);
        color: var(--hint-text-color, $hint-text-color);
        z-index: 1;

        &::after {
            content: '';
            bottom: -10px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            border: 6px solid transparent;
            border-top-color: var(--hint-bg, $hint-bg);
        }
    }

    &.error {
        border-color: var(--danger, $danger);

        & > legend {
            color: var(--danger, $danger);
        }

        .hint-text {
            white-space: normal;
            background-color: var(--danger, $danger);

            &::after {
                border-top-color: var(--danger, $danger);
            }
        }
    }

    &.disabled {
        opacity: 0.7;
        pointer-events: none;

        input {
            color: var(--disabled-color, $disabled-color);
        }
    }

    & > .btn {
        top: 6px;
        right: 8px;
        position: absolute;
    }
}
</style>
