<script setup>
import { ref } from 'vue';
import { useLeadActions } from '@lead';
import { useProfileActions } from '@profile';
import { useCommentActions, useCommentComponents } from '@comment';

const Comment = useCommentComponents('Comment');

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const getCommentResource = useCommentActions('getCommentResource');
const comments = getCommentResource(
    {
        includes: ['user'],
        filters: { lead_id: [lead.model.id] },
    },
    true,
    'LeadComments Component'
);

const newComment = ref();

function addComment(event) {
    if (event.shiftKey) {
        return false;
    }

    comments.create({
        lead_id: lead.model.id,
        user_id: { name: profile.model.id },
        body: newComment.value.trim(),
    }).then(comment => {
        newComment.value = '';
    });
}

function deleteComment(id) {
    comments.delete(id).then(() => {
        const index = comments.state.findIndex(comment => comment.id === id);

        if (index > -1) {
            comments.state[index].deleted_at = new Date().toLocaleString();
        }
    });
}
</script>

<template>
    <div class="comments">
        <g-preloader-overlay v-if="comments.isLoading"/>
        <comment v-for="comment in comments.state"
                 v-bind:key="`comment-${comment.id}`"
                 v-bind:comment="comment"
                 v-on:delete="deleteComment(comment.id)"
                 v-on:restore="comments.restore(comment.id)">
            <div class="text">{{ comment.body }}</div>
        </comment>
    </div>
    <form class="comment-form" v-if="profile.can('Comment Make')" v-on:submit.prevent="addComment">
        <g-textarea v-model="newComment" rows="2"
                    maxlength="1000"
                    v-on:keyup.shift.enter.stop
                    v-on:keyup.enter.prevent="addComment"/>
        <g-button class="save-btn">
            <g-symbol name="send" width="20" height="20"/>
        </g-button>
    </form>
</template>

<style scoped lang="scss">
.comments {
    padding: 20px 50px 20px 60px;
}

.comment-form {
    gap: 3px;
    display: flex;
    bottom: 0;
    position: sticky;
    padding: 20px 50px 20px 60px;
    color: var(--env-text-color, $env-title-color);
}

.g-textarea {
    flex-grow: 1;
    border-radius: 10px 0 0 10px;
    background-color: var(--field-active-bg, $field-active-bg);
}

.g-button {
    padding: 0;
    width: 40px;
    font-size: 0 !important;
    height: auto !important;
    background-color: var(--primary, $primary);

    &:hover {
        background-color: var(--primary-hover, $primary-hover);
    }
}

.text {
    white-space: pre-wrap;
}
</style>