<script setup>
import { defineProps, defineEmits, reactive, watch, computed, ref } from 'vue';
import { useShipActions, verifyField } from '@/Ship';
import { useTradingActions } from '@trading';
import { useLeadActions } from '@lead';
import { useSettingsActions } from "@settings";

const props = defineProps(['payload']);
const emit = defineEmits(['performed', 'close']);

const getLeadResource = useLeadActions('getLeadResource');
const lead = getLeadResource();

const getSettingsResource = useSettingsActions('getSettingsResource');
const settings = getSettingsResource();

const onlyNumberInput = useShipActions('onlyNumberInput');

const marketCurrency = (props.payload.pair.market === 'forex' || props.payload.pair.market === 'commodities') ? 'USD' : 'USDT';
const counterPrice = props.payload.pair.counter !== marketCurrency
    ? props.payload.side === 'Long'
        ? props.payload.pl / ((props.payload.close_price - props.payload.entry_price) * props.payload.value)
        : props.payload.pl / ((props.payload.entry_price - props.payload.close_price) * props.payload.value)
    : 1;

function profitCalculate() {
    const priceDelta = position.side === 'Long'
        ? position.close_price - position.entry_price
        : position.entry_price - position.close_price;

    //const swap = ((position.usdt_amount * position.swap) / 100);
    const swap = ((position.usdt_amount * position.swap) / 100) * counterPrice;
    const pl = ((priceDelta * counterPrice) * position.value) - swap;

    return Number(pl.toFixed(5));
}

const isLock = ref(true);
const errors = reactive({
    entryPrice: false,
    closePrice: false,
    profit: false,
    takeProfit: false,
    stopLoss: false,
    openedAt: false,
    closeAt: false,
});

watch(errors, () => {
    isLock.value = false;
    for (const key in errors) {
        if (errors[key]) {
            isLock.value = true;
            break;
        }
    }
});

const pair = `${props.payload.pair.base}:${props.payload.pair.counter}`;

const targetPayload = reactive({});
const position = new Proxy(targetPayload, {
    get(target, key) {
        return key in target
            ? target[key]
            : props.payload[key];
    },
    set(target, key, value) {
        if (value != target[key]) {
            target[key] = value;
        }

        return true;
    }
});

const profit = computed({
    get() {
        return position.pl;
    },
    set(value) {
        position.pl = Number(value);

        errors.profit = ((lead.model.balance - Number(props.payload.pl) + position.pl) < 0)
            ? 'Not enough Balance!'
            : errors.profit = null;

        return true;
    }
});

const closePrice = computed({
    get() {
        return Number(position.close_price);
    },
    set(value) {
        position.close_price = Number(value);
        profit.value = profitCalculate();

        errors.closePrice = (value > 0)
            ? null
            : errors.closePrice = 'Close Price must be greater than zero';

        return true;
    }
});

const entryPrice = computed({
    get() {
        return Number(position.entry_price);
    },
    set(value) {
        position.entry_price = Number(value);
        position.value = Number((position.total_amount / value).toFixed(5));
        profit.value = profitCalculate();

        errors.entryPrice = (value > 0)
            ? null
            : errors.entryPrice = 'Entry Price must be greater than zero';

        return true;
    }
});

const amount = computed({
    get() {
        return position.amount;
    },
    set(value) {
        const marketCurrency = (position.pair.market === 'forex' || position.pair.market === 'commodities') ? 'USD' : 'USDT';

        const usdtAmount = (props.payload.pair.counter !== marketCurrency)
            ? value * position.entry_price // props.payload.entry_price
            : value;

        position.amount = Number(value);
        position.usdt_amount = usdtAmount;
        position.total_amount = value * position.leverage;
        position.value = Number((position.total_amount / position.entry_price).toFixed(5));
        profit.value = profitCalculate();

        errors.amount = (value > 0)
            ? null
            : 'Amount must be greater than zero';

        return true;
    }
});

const leverage = computed({
    get() {
        return position.leverage;
    },
    set(value) {
        position.leverage = Number(value);
        position.total_amount = position.amount * value;
        position.value = Number((position.total_amount / position.entry_price).toFixed(5));
        profit.value = profitCalculate();

        return true;
    }
});

const value = computed({
    get() {
        return position.value;
    },
    set(value) {
        position.value = Number(value);
        const price = (position.amount * position.leverage) / value;
        position.entry_price = ['USDT', 'USD'].includes(String(props.payload.pair.counter))
            ? position.entry_price = Number(price.toFixed(2))
            : position.entry_price = Number(price.toFixed(5));
        profit.value = profitCalculate();

        errors.value = (value > 0)
            ? null
            : 'Value must be greater than zero';

        return true;
    }
});

const side = computed({
    get() {
        return position.side;
    },
    set(value) {
        position.side = value;
        const tmp = position.take_profit;
        position.take_profit = position.stop_loss;
        position.stop_loss = tmp;
        profit.value = profitCalculate();

        return true;
    }
});

const takeProfit = computed({
    get() {
        return position.take_profit;
    },
    set(value) {
        position.take_profit = value;

        if (value > 0) {
            const factor = position.side === 'Long' ? 1 : -1;

            errors.takeProfit = ((position.entry_price - value) * factor < 0)
                ? null
                : `Take Profit mast be ${(factor > 0 ? 'greater' : 'less')} than the Entry Price!`;
        } else {
            errors.takeProfit = 'Take Profit must be greater than zero';
        }

        return true;
    }
});

const stopLoss = computed({
    get() {
        return position.stop_loss;
    },
    set(value) {
        position.stop_loss = value;

        if (value > 0) {
            const factor = position.side === 'Long' ? -1 : 1;

            errors.stopLoss = ((position.entry_price - value) * factor < 0)
                ? null
                : errors.stopLoss = `Stop Loss must be ${(factor > 0 ? 'greater' : 'less')} than the Entry Price!`;
        } else {
            errors.stopLoss = 'Stop Loss must be greater than zero';
        }

        return true;
    }
});

const swap = computed({
    get() {
        return Number(position.swap);
    },
    set(value) {
        position.swap = Number(value);
        profit.value = profitCalculate();

        return true;
    }
});

function calculateSwap(opened, closed) {

    const dayCount = ((closed - opened) / 86400000) >> 0;

    return {
        crypto: (lead.model.specifics?.crypto_fee || settings.general.defaultCryptoFee || 0.001),
        stocks: (lead.model.specifics?.stock_fee || settings.general.defaultStockFee || 0.001),
        //forex: (lead.model.specifics?.forex_fee || settings.general.defaultForexFee || 0.001),
        commodities: (lead.model.specifics?.commodity_fee || settings.general.defaultCommodityFee || 0.001),
    }[props.payload.pair.market] * dayCount;
}

const openedAt = computed({
    get() {
        return position.created_at;
    },
    set(value) {
        position.created_at = value;

        const opened = new Date(value);
        opened.setUTCHours(0, 0, 0);
        const closed = Date.parse(closedAt.value.replace(/\./g, '-'));

        swap.value = Number(calculateSwap(opened.valueOf(), closed).toFixed(3));

        return true;
    }
});

const closedAt = computed({
    get() {
        return position.deleted_at;
    },
    set(value) {
        position.deleted_at = value;

        const opened = new Date(openedAt.value);
        opened.setUTCHours(0, 0, 0);
        const closed = Date.parse(value.replace(/\./g, '-'));

        swap.value = Number(calculateSwap(opened.valueOf(), closed).toFixed(3));

        return true;
    }
});

const getHistoryResource = useTradingActions('getPositionHistoryResource');
const resource = getHistoryResource();

function savePosition() {

    for (const key in targetPayload) {
        if (typeof targetPayload[key] === 'number') {
            if (targetPayload[key] === Number(props.payload[key])) {
                delete targetPayload[key];
            }
        } else if (targetPayload[key] === props.payload[key]) {
            delete targetPayload[key];
        }
    }

    resource.save(props.payload.id, targetPayload).then(() => {
        const cpPosition = {};

        for (const key in targetPayload) {
            if (Object.prototype.hasOwnProperty.call(targetPayload, key)) {
                cpPosition[key] = targetPayload[key];
            }

            if (key === 'pl') {
                const plDelta = targetPayload[key] - resource.state[resource.index].pl;
                lead.state[lead.index].balance = Number(lead.model.balance) + plDelta;
            }
        }

        Object.assign(resource.state[resource.index], targetPayload);
        emit('close');
    });
}

</script>

<template>
    <form v-on:submit.prevent="savePosition">
        <fieldset>
            <legend>ID</legend>
            <g-input v-model="position.id" readonly>
                <g-symbol name="lock" width="18" height="18"/>
            </g-input>
        </fieldset>

        <fieldset>
            <legend>{{ payload.pair.market.toUpperCase() }}</legend>
            <g-input v-model="pair" readonly>
                <g-symbol name="lock" width="18" height="18"/>
            </g-input>
        </fieldset>

        <fieldset>
            <legend>{{ $t('trading.side', 'Side') }}</legend>
            <g-select v-model="side"
                      v-bind:options="[{value: 'Long'},{value: 'Short'}]"
                      option-text="value"
                      option-value="value"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('trading.status', 'Status') }}</legend>
            <g-select v-model="position.status"
                      v-bind:options="[{value: 'Trader Close'},{value: 'Broker Close'}, {value: 'Take Profit'}, {value: 'Stop Loss'}, {value: 'Liquidation'}]"
                      option-text="value"
                      option-value="value"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('trading.entry_price', 'Entry Price') + ', ' + payload.pair.counter }}</legend>
            <g-input v-model="entryPrice"
                     v-bind:error="errors.entryPrice"
                     v-on:keypress="onlyNumberInput"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('trading.close_price', 'Close Price') + ', ' + payload.pair.counter }}</legend>
            <g-input v-model="closePrice" v-bind:error="errors.closePrice"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('trading.amount', 'Amount') + ', ' + payload.pair.counter }}</legend>
            <g-input v-model="amount" v-bind:error="errors.amount" v-on:keypress="onlyNumberInput"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('trading.leverage', 'Leverage') }}</legend>
            <g-select v-model="leverage"
                      v-bind:options="[{n: '✕1', v: 1},{n: '✕2', v: 2}, {n: '✕3', v: 3}, {n: '✕5', v: 5}, {n: '✕10', v: 10}, {n: '✕15', v: 15}, {n: '✕20', v: 20}]"
                      option-text="n"
                      option-value="v"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('trading.total', 'Total') + ', ' + payload.pair.counter }}</legend>
            <g-input v-model="position.total_amount" readonly>
                <g-symbol name="lock" width="18" height="18"/>
            </g-input>
        </fieldset>

        <fieldset>
            <legend>{{ $t('trading.value', 'Value') + ', ' + payload.pair.base }}</legend>
            <g-input v-model="value" v-bind:error="errors.value" v-on:keypress="onlyNumberInput"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('trading.pl', 'Profit & Loss') + ', USDT' }}</legend>
            <g-input v-model="profit" v-bind:error="errors.profit" readonly>
                <g-symbol name="lock" width="18" height="18"/>
            </g-input>
        </fieldset>

        <fieldset>
            <legend>{{ $t('trading.swap', 'Swap') }}</legend>
            <g-input v-model="swap" v-on:keypress="onlyNumberInput"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('trading.take_profit', 'Take Profit') + ', ' + payload.pair.counter }}</legend>
            <g-input v-model="takeProfit" v-on:keypress="onlyNumberInput" v-bind:error="errors.takeProfit"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('trading.stop_loss', 'Stop Loss') + ', ' + payload.pair.counter }}</legend>
            <g-input v-model="stopLoss" v-bind:error="errors.stopLoss" v-on:keypress="onlyNumberInput"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('base.opened_at', 'Opened at') }}</legend>
            <g-input v-model="openedAt"/>
        </fieldset>

        <fieldset>
            <legend>{{ $t('base.closed_at', 'Closed at') }}</legend>
            <g-input v-model="closedAt"/>
        </fieldset>

        <g-button class="cancel-btn" v-on:click="$emit('close')">{{ $t('base.cancel', 'Cancel') }}</g-button>
		<g-button class="save-btn" v-bind:disabled="isLock">{{ $t('base.save', 'Save') }}</g-button>
    </form>
</template>

<style scoped lang="scss">
form {
    max-width: 480px;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr 1fr;
}

.g-button {
    margin-top: 6px;
}

.g-input,
.g-select {
    background-color: var(--body-bg, $body-bg);
}
</style>