<script setup>
import { computed, ref, defineComponent, reactive } from 'vue';
import { useLeadActions, useLeadComponents } from '@lead';
import { useCountryActions } from '@country';
import { useProfileActions } from '@profile';
import {AutoSaveStack, i18n, PopUpManager as PopUp, STATE} from '@/Ship';
import GButton from "@/Ship/components/global/button/GButton.vue";

const getProfileResource = useProfileActions('getProfileResource');
const getCountryResource = useCountryActions('getCountryResource');
const getLeadResource = useLeadActions('getLeadResource');

const lead = getLeadResource();
const profile = getProfileResource();
const countries = getCountryResource();

const {
    Condition,
    Fees,
    Marketing,
    LeadDetails,
} = useLeadComponents();
defineComponent([Condition, Fees, Marketing, LeadDetails]);

const detailsExpand = ref(false);

const payload = reactive({});

function setValue(key, value) {
    if (lead.model[key] === value) {
        delete payload[key];
    } else {
        payload[key] = value;
    }
}

function payloadChange(data) {
    Object.keys(data).forEach(key => {
        setValue(key, data[key]);
    });
}

const firstName = computed({
    get: () => {
        return payload.first_name || lead.model.first_name
    },
    set: (value) => {
        setValue('first_name', value);
        return true;
    }
});

const lastName = computed({
    get: () => {
        return payload.last_name || lead.model.last_name
    },
    set: (value) => {
        setValue('last_name', value);
        return true;
    }
});

const password = computed({
    get() {
        return payload.password || lead.model.password;
    },
    set(value) {
        setValue('password', value);
        return true;
    }
});

const email = computed({
    get() {
        return profile.cant('Lead ContactsView')
            ? lead.model.email.replace(/^(.{2})(.+)@(.+)\.(.+)$/, (match, p1, p2, p3, p4) => `${p1}${p2.replace(/\w/g, 'ּ*')}@${p3}.${p4.replace(/\w/g, 'ּ*')}`)
            : payload.email || lead.model.email;
    },
    set(value) {
        setValue('email', value);
        return true;
    }
});

const phone = computed({
    get() {
        return profile.cant('Lead ContactsView')
            ? lead.model.phone.replace(/^\+?(\d{3})(\d+)(\d{3})$/, (match, p1, p2, p3) => `+${p1}${p2.replace(/./g, '*')}${p3}`)
            : payload.phone || lead.model.phone;
    },
    set(value) {
        setValue('phone', value);
        return true;
    }
});

const altPhone = computed({
    get() {
        return profile.cant('Lead ContactsView')
            ? lead.model.alt_phone.replace(/^\+?(\d{3})(\d+)(\d{3})$/, (match, p1, p2, p3) => `+${p1}${p2.replace(/./g, '*')}${p3}`)
            : lead.model.alt_phone;
    },
    set(value) {
        setValue('alt_phone', value);
        return true;
    }
});

const country = computed({
    get: () => {
        return payload.country_code || lead.model.country_code
    },
    set: (value) => {
        setValue('country_code', value);
        return true;
    }
});

const language = computed({
    get: () => {
        return payload.language || lead.model.language
    },
    set: (value) => {
        setValue('language', value);
        return true;
    }
});

const description = computed({
    get: () => {
        return payload.description || lead.model.description
    },
    set: (value) => {
        setValue('description', value);
        return true;
    }
});

// const telegram = computed({
//     get() {
//         return profile.cant('Lead ContactsView')
//             ? lead.model.telegram.replace(/^@?(.+)(.{3})$/, (match, p1, p2) => `@${p1.replace(/\w/g, '*')}${p2}`)
//             : lead.model.telegram;
//     },
//     set(value) {
//         lead.model.telegram = value;
//         return true;
//     }
// });

function editPassword() {
    PopUp.open(require('@lead/components/PopUps/EditPassword')).setCaption(i18n.global.t('profile.change_password', 'Change Password'))
}

const errors = reactive({});

function save() {
    if (!!payload.specifics){
        payload.specifics = Object.assign(lead.model.specifics, payload.specifics);
    }

    lead.save(lead.model.id, payload).then(({ refusal }) => {

        Object.keys(refusal).forEach(key => {
            delete payload[key];
            errors[key] = refusal[key];
        });

        Object.assign(lead.state[lead.index], payload);
        resetAll();
    });
}

function resetAll() {
    Object.keys(payload).forEach(key => delete payload[key]);
}

</script>

<template>
    <form v-on:submit.prevent>

        <div class="profile section">
            <g-caption size="3" weight="600">
                {{ $t('lead.profile') }}
            </g-caption>

            <div class="container">
                <fieldset>
                    <legend v-bind:class="{ changed: !!payload.first_name }">
                        {{ $t('lead.first_name', 'First name') }}
                    </legend>
                    <g-input v-model="firstName" v-bind:backlight="!!payload.first_name"/>
                </fieldset>

                <fieldset v-bind:class="{ changed: !!payload.last_name }">
                    <legend v-bind:class="{ changed: !!payload.last_name }">
                        {{ $t('lead.last_name', 'Last name') }}
                    </legend>
                    <g-input v-model="lastName" v-bind:backlight="!!payload.last_name"/>
                </fieldset>

                <fieldset>
                    <legend v-bind:class="{ changed: !!payload.phone }">
                        {{ $t('lead.phone', 'Phone') }}
                    </legend>
                    <g-input v-model="phone"
                             v-bind:backlight="!!payload.phone"
                             type="tel"
                             v-bind:readonly="profile.cant('Lead ContactsView')">
                        <g-symbol v-if="profile.cant('Lead ContactsView')" name="lock" width="18" height="18"/>
                    </g-input>
                </fieldset>

                <fieldset>
                    <legend v-bind:class="{ changed: !!payload.alt_phone }">
                        {{ $t('lead.additional_phone', 'Additional phone') }}
                    </legend>
                    <g-input v-model="altPhone"
                             v-bind:backlight="!!payload.alt_phone"
                             type="tel"
                             v-bind:readonly="profile.cant('Lead ContactsView')">
                        <g-symbol v-if="profile.cant('Lead ContactsView')" name="lock" width="18" height="18"/>
                    </g-input>
                </fieldset>

                <fieldset>
                    <legend v-bind:class="{ changed: !!payload.email }">Email</legend>
                    <g-input v-model="email"
                             v-bind:backlight="!!payload.email"
                             type="email"
                             v-bind:readonly="profile.cant('Lead ContactsView')">
                        <g-symbol v-if="profile.cant('Lead ContactsView')" name="lock" width="18" height="18"/>
                    </g-input>
                </fieldset>

                <!--
                <fieldset>
                    <legend>{{ $t('lead.telegram', 'Telegram') }}</legend>
                    <g-input v-model="telegram" v-bind:readonly="profile.cant('Lead ContactsView')">
                        <g-symbol v-if="profile.cant('Lead ContactsView')" name="lock" width="18" height="18"/>
                    </g-input>
                </fieldset>
                -->

                <fieldset>
                    <legend v-bind:class="{ changed: !!payload.password }">
                        {{ $t('lead.password', 'Password') }}
                    </legend>
                    <g-input v-model="profile.password"
                             v-bind:backlight="!!payload.password"
                             type="password"
                             v-bind:label="$t('lead.password')"
                             readonly>
                        <g-symbol name="lock" width="18" height="18"/>
                        <g-symbol name="edit" width="22" height="22" v-on:click="editPassword"/>
                    </g-input>
                </fieldset>

                <fieldset>
                    <legend v-bind:class="{ changed: !!payload.country_code }">
                        {{ $t('lead.country', 'Country') }}
                    </legend>
                    <g-select v-model="country"
                              v-bind:backlight="!!payload.country_code"
                              v-bind:options="countries.state"
                              option-value="code"
                              option-text="name"
                              v-bind:readonly="profile.cant('Lead CountryEdit')"
                              v-bind:error="errors.country_code"/>
                </fieldset>

                <fieldset>
                    <legend v-bind:class="{ changed: !!payload.language }">
                        {{ $t('lead.language', 'Language') }}
                    </legend>
                    <g-combobox v-model="language"
                                v-bind:backlight="!!payload.language"
                                v-bind:options="['AR', 'DE', 'EN', 'ES', 'ET', 'IT', 'LT', 'LV', 'KA', 'KK', 'HE', 'PL', 'PT', 'RU']"/>
                </fieldset>

                <fieldset v-if="profile.can('Lead DescriptionView')" class="lead-description">
                    <legend v-bind:class="{ changed: !!payload.description }">
                        {{ $t('lead.affiliate_comment', 'Affiliate comment') }}
                    </legend>
                    <g-textarea v-model="description"
                                rows="3"
                                v-bind:backlight="!!payload.description"
                                v-bind:label="$t('description', 'Description')"
                                v-bind:readonly="!profile.can('Lead DescriptionEdit')"
                                v-bind:error="errors.description"/>
                </fieldset>
            </div>
        </div>

        <template v-if="profile.can('Lead DetailsView')">
            <div class="details section" v-bind:class="{ collapse: !detailsExpand }">
                <g-caption size="3" weight="600">
                    {{ $t('lead.details', 'Details') }}
                </g-caption>
                <div class="container expand">
                    <lead-details v-bind:payload="payload" v-on:change="payloadChange"/>
                </div>
            </div>

            <g-flex justify="center">
                <g-link-button v-on:click="detailsExpand = !detailsExpand">{{ detailsExpand ? 'Hide Details' : 'Show Details' }}</g-link-button>
            </g-flex>
        </template>

        <div class="condition section">
            <g-caption size="3" weight="600">
                {{ $t('lead.condition', 'Condition') }}
            </g-caption>
            <div class="container expand">
                <condition v-bind:payload="payload" v-bind:errors="errors" v-on:change="payloadChange"/>
            </div>
        </div>

        <div class="marketing section">
            <g-caption size="3" weight="600">
                {{ $t('lead.campaigns', 'Campaigns') }}
            </g-caption>

            <div class="expand">
                <marketing v-bind:campaigns="lead.model.campaigns"/>
            </div>
        </div>

        <transition name="savebar">
            <g-flex v-if="Object.keys(payload).length" class="btn-bar" justify="end" align="center" gap="5">
                <g-button class="cancel-btn" type="button" v-on:click="resetAll">
                    <g-symbol name="refresh" width="16" height="16"/>
                    {{ $t('base.reset', 'Reset') }}
                </g-button>
                <g-button class="save-btn" type="button" v-on:click="save">
                    <g-symbol name="save" width="16" height="16"/>
                    {{ $t('base.save', 'Save') }}
                </g-button>
            </g-flex>
        </transition>
    </form>
</template>

<style scoped lang="scss">
legend.changed {
    color: var(--seconsary, $secondary);
}

.section {
    position: relative;
    padding: 20px 50px 20px 80px;
    color: var(--env-text-color, $env-text-color);

    &.details {
        overflow: hidden;
        transition: max-height 0.4s, padding 0.6s;

        &:not(.collapse) {
            max-height: 600px;
        }
    }

    & > .g-caption {
        color: var(--env-title-color, $env-title-color);
    }

    & > .container {
        align-items: end;
        display: grid;
        grid-gap: 16px;
        grid-template-columns: 1fr 1fr;

        & > .full-row {
            grid-column: 1/3;
        }
    }
}

fieldset {
    & > legend {
        color: var(--env-title-color, $env-title-color);
    }

    color: var(--env-text-color, $env-text-color);
}

.marketing {
    overflow-x: scroll;
    height: 50%;
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color) transparent;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 1ex;
        background-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color);
    }
}

.g-input,
.g-textarea {
    &.filled,
    &:focus-within {
        background-color: var(--input-active-bg, $input-active-bg);
    }

    &:deep(svg) {
        fill: var(--env-text-color, $env-title-color);
    }
}

.lead-description {
    grid-column: 1/3;
}

.edit-btn {
    width: 45px;
    position: relative;
    background-color: transparent;
    fill: var(--env-title-color, $env-title-color);
    border: none;

    &::after {
        left: 20%;
        transform: translateX(-50%);
        top: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
    }

    &::before {
        left: 50%;
        transform: translateX(-50%);
        top: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
        border-bottom-color: var(--tooltip-bg, $tooltip-bg);
    }

    &:hover {
        background-color: var(--success, $success);
        fill: var(--btn-primary-color, $btn-primary-color);
    }
}

.collapse {
    max-height: 0;
    overflow: hidden;
    padding: 0 50px 0 80px;
}

.btn-bar {
    padding: 20px 15px;
    bottom: 0;
    margin: 0 -5px;
    position: sticky;
    background-color: var(--primary, $primary);
    box-shadow: 0 -5px 15px -5px #000000aa;
    border-radius: var(--bar-rounding, $bar-rounding);
    //border-top: 4px solid var(--secondary, $secondary);

    & > .g-button {
        padding: 0 20px;
        min-width: 100px;
        display: flex;
        align-items: center;
        gap: 8px;
        background-color: transparent;
        fill: var(--main-text-color, $main-text-color);

        &.cancel-btn {
            color: var(--main-text-color, $main-text-color);
        }

        &:hover {
            fill: var(--title-text-color, $title-text-color);
            color: var(--title-text-color, $title-text-color);
            background-color: var(--primary-hover, $primary-hover);
        }
    }
}

.savebar-enter-active {
    transition: bottom 0.6s;
}

.savebar-enter-from,
.savebar-leave-active {
    bottom: -100%;
    transition: bottom 0.6s;
}
</style>