<script setup>

import { ref, toRefs, watchEffect, defineProps, defineEmits } from 'vue';

const props = defineProps({
    modelValue: {},
    index: { type: Number },
    options: { type: Object, default: () => {}},
    optionText: { Type: [String, Object] },
    optionValue: { Type: [String, Object] },
    textTransform: { type: String },
    readonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    backlight: { type: Boolean, default: false },
    error: { type: String, default: ''}
});
const emit = defineEmits(['update:modelValue', 'update:index', 'select']);

const { index } = toRefs(props);

const isFocus = ref(false);
const toTop = ref(false);
const datalist = ref(null);
const selected = ref('');
const showList = () => {
    isFocus.value = true;
    const { y } = datalist.value.getBoundingClientRect();
    toTop.value = (y + 300) > screen.availHeight;
};
const hideList = () => {
    setTimeout(() => {
        isFocus.value = false;
    }, 200);
};
const processing = (index) => {
    emit('update:index', index);
    emit('update:modelValue', props.options[index][props.optionValue]);
    emit('select', props.options[index][props.optionValue]);
};

watchEffect(() => {
    const value = props.options.find(item => item && item[props.optionValue] === props.modelValue);
    if (value) {
        selected.value = value[props.optionText];
    } else {
        selected.value = '';
    }
});
</script>

<template>
    <div class="g-select" v-bind:class="{ error, disabled, readonly }">

        <label class="field" v-bind:class="{ filled: !!modelValue, focus: isFocus, backlight }">

            <input v-model="selected"
                   v-bind="$attrs"
                   v-bind:value="selected"
                   v-on:focus="showList"
                   v-on:blur="hideList"
                   placeholder=" "
                   readonly>

            <g-symbol v-if="readonly || disabled" class="arrow" name="lock" width="20" height="20"/>
            <g-symbol v-else
                      class="arrow"
                      name="arrow-down"
                      width="20"
                      height="20"/>

            <span ref="datalist"
                  class="datalist"
                  v-bind:class="{ 'to-top': toTop }">
                <label v-for="(option, idx) in options"
                       v-bind:key="option && option[optionValue]"
                       v-bind:class="{active: option && option[optionValue] === modelValue}">
                    <input v-model="index"
                           v-bind:class="textTransform"
                           type="radio"
                           v-bind:value="idx"
                           v-on:click="processing(idx)"
                           hidden>
                    {{ option && option[optionText] }}
                </label>
            </span>

            <span v-if="error" class="hint-text">{{ error }}</span>
        </label>
    </div>
</template>

<style lang="scss" scoped>
.field {
    position: relative;
    padding: 0 10px 0 0;
    display: flex;
    white-space: nowrap;
    align-items: center;
    box-sizing: border-box;
    color: inherit;
    height: var(--input-height, $input-height);
    border: 1px solid var(--input-border, $input-border);
    border-radius: var(--input-rounding, $input-rounding);
    //background-color: var(--select-active-bg, $select-active-bg);

    &.backlight {
        border-color: var(--secondary, $secondary);
        box-shadow: inset 0 0 2px 0 var(--secondary-hover, $secondary-hover);
    }

    input {
        flex-grow: 1;
        width: 100%;
        height: 100%;
        border: none;
        padding-left: 10px;
        box-sizing: border-box;
        color: inherit;
        background-color: transparent;
        font-size: var(--input-font-size, $input-font-size);
        font-family: var(--font-primary, $font-primary);

        &::placeholder {
            color: transparent;
        }

        &:focus {
            outline: none;
        }

        &:focus ~ .arrow {
            transform: rotate(180deg);
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            background: transparent;
            transition: background-color 5000s ease-in-out 0s;
            -webkit-text-fill-color: var(--main-text-color, $main-text-color);
        }
    }

    & > .arrow {
        flex-shrink: 0;
        transition: transform 0.2s;
        fill: var(--secondary, $secondary);
    }

    & > .datalist {
        z-index: 3;
        left: -1px;
        max-height: 300px;
        width: calc(100% + 2px);
        position: absolute;
        overflow: auto;
        box-sizing: border-box;
        background-color: var(--secondary, $secondary);
        border-radius: var(--dropdown-rounding, $dropdown-rounding);
        //border: 1px solid var(--dropdown-list-border, $dropdown-list-border);
        box-shadow: 0 0 12px -5px #000000bb;
        scrollbar-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color) transparent;
        scrollbar-width: thin;
        transition:
            opacity 0.5s 0.1s,
            max-height 0.3s;
        text-transform: v-bind(textTransform);

        &::-webkit-scrollbar {
            width: 6px;
        }

        &::-webkit-scrollbar-thumb {
            -webkit-border-radius: 1ex;
            background-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color);
        }

        &.to-top {
            bottom: 100%;
            margin-bottom: 8px;
        }

        &:not(.to-top) {
            top: 100%;
            margin-top: 2px;
        }

        label {
            display: block;
            cursor: pointer;
            padding: 3px 15px;
            font-weight: 400;
            font-size: 14px;
            text-align: left;
            white-space: nowrap;
            color: var(--main-text-color, $main-text-color);

            &:first-child {
                padding-top: 10px;
            }

            &:last-child {
                padding-bottom: 10px;
            }

            &.active {
                pointer-events: none;
                color: var(--selected-color, $selected-color);
            }

            &:not(.active):hover {
                color: var(--title-text-color, $title-text-color);
            }
        }
    }

    &:not(.focus) > .datalist {
        opacity: 0;
        max-height: 0;
        border-width: 0;
        overflow: hidden;
        transition:
            opacity 0.2s 0.1s,
            max-height 0.3s,
            border-width 0s 0.3s;
    }

    &.focus,
    &.filled {
        //background-color: var(--select-active-bg, $select-active-bg);
        background-color: transparent;
    }
}

.readonly {
    pointer-events: none;
}

.hint-text {
    bottom: 90%;
    left: 50%;
    width: 100%;
    padding: 5px 15px;
    border-radius: 3px;
    position: absolute;
    transform: translateX(-50%);
    background-color: var(--hint-bg, $hint-bg);
    color: var(--hint-text-color, $hint-text-color);
    z-index: 1;

    &::after {
        content: '';
        bottom: -10px;
        left: 50%;
        position: absolute;
        transform: translateX(-50%);
        border: 6px solid transparent;
        border-top-color: var(--hint-bg, $hint-bg);
    }
}

.error {
    & > .field {
        border-color: var(--danger, $danger);

        & > legend {
            color: var(--danger, $danger);
        }
    }

    .hint-text {
        white-space: normal;
        background-color: var(--danger, $danger);

        &::after {
            border-top-color: var(--danger, $danger);
        }
    }
}

.disabled {
    opacity: 0.7;
    pointer-events: none;

    legend {
        color: var(--disabled-color, $disabled-color);
    }

    input {
        color: var(--disabled-color, $disabled-color);
    }
}
</style>
