<template>
    <div class="finances environment">
        <g-flex class="bar" align="center" justify="between">
            <g-flex class="tab-bar" justify="between">
                <g-tabs v-model="part" v-bind:tab-list="partList"/>
            </g-flex>

            <g-flex align="center" class="total" gap="2">
                Total:
                <g-symbol name="tether" width="18" height="18"/>
                <span class="sum">{{ total.sum }}</span>
                / {{ total.count }}
            </g-flex>
        </g-flex>

        <component v-bind:is="part" v-model:total="total"/>
    </div>
</template>

<script>

import { ref } from 'vue'
import { i18n } from '@/Ship'
import { useProfileActions } from '@profile'
import { useFinanceComponents } from '@finance'

const {
    Withdrawals,
    Replenishments
} = useFinanceComponents();

const getProfileResource = useProfileActions('getProfileResource');

export default {
    name: 'Environment',
    components: {
        Withdrawals,
        Replenishments
    },

    setup() {
        const profile = getProfileResource();

        const total = ref({sum: 0, count: 0});
        const part = ref();
        const partList = {};
        if (profile.can('Deposit View')) {
            partList['Replenishments'] = i18n.global.t('finances.replenishments', 'Replenishments');
            part.value = 'Replenishments';
        }

        if (profile.can('Withdraw View')) {
            partList['Withdrawals'] = i18n.global.t('finances.withdrawals', 'Withdrawals');
            if (!profile.can('Deposit View')) {
                part.value = 'Withdrawals';
            }
        }

        return {
            part,
            partList,
            total,
            profile
        }
    }
}
</script>

<style lang="scss" scoped>
.environment {
    height: 100%;
    margin: 0 6px;
    position: relative;
    border-radius: 12px;
    font-size: 0.9rem;
    border: 3px solid var(--secondary, $secondary);
    background-color: var(--env-bg, $env-bg);
    color: var(--env-text-color, $env-text-color);
}

.bar {
    z-index: 2;
    top: 0;
    position: sticky;
    padding: 12px 20px;
    background-color: var(--env-bg, $env-bg);
    box-shadow: 0 5px 20px -10px #00000055;
}

.total {
    & > .g-symbol {
        fill: var(--secondary, $secondary);
    }
}
</style>
