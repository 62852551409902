<script setup>

import { ref } from 'vue';
import { useTradingComponents } from '@trading';
import { useProfileActions } from '@profile';
import { useLeadComponents } from '@lead';
import { PopUpManager as PopUp } from '@/Ship';

const Fees = useLeadComponents('Fees');

const getProfileResource = useProfileActions('getProfileResource');
const profile = getProfileResource();

const {
    History,
    Positions,
} = useTradingComponents();

const tabs = {
    History,
    Positions,
};

const tab = ref('Positions');
</script>

<template>
    <div class="fees section" v-if="profile.can('Lead FeeView')">
        <g-caption size="3" weight="600">
            {{ $t('lead.fees', 'Fees') }}
        </g-caption>

        <fees/>
    </div>

	<div class="tab-bar">
        <span v-if="profile.can('Position View')"
              class="tab-btn"
              v-bind:class="{active: tab === 'Positions'}"
              v-on:click="tab = 'Positions'">
            {{ $t('trading.open_positions', 'Open Positions') }}
        </span>
        <span v-if="profile.can('Position View')"
              class="tab-btn"
              v-bind:class="{active: tab === 'History'}"
              v-on:click="tab = 'History'">
            {{ $t('trading.positions_history', 'Positions History') }}
        </span>
        <div class="toolbar">
            <g-button class="with-tooltip"
                      type="button"
                      v-on:click="PopUp.open(require('@trading/components/PopUps/DealSettings.vue')).setCaption($t('trading.deal_settings', 'Deal settings'))"
                      v-bind:data-tooltip="$t('trading.deal_settings', 'Deal settings')">
                <g-symbol name="settings" width="20" height="20"/>
            </g-button>
        </div>
	</div>

	<component v-bind:is="tabs[tab]"/>
</template>

<style lang="scss" scoped>
.section > .g-caption {
    padding: 10px 50px 10px 60px;
    color: var(--env-title-color, $env-title-color);
}

.tab-bar {
    padding: 10px 50px 10px 60px;
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: var(--env-bg, $env-bg);

    & > .tab-btn {
        font-size: 1.2rem;
        color: var(--env-text-color, $env-text-color);

        &.active {
            font-weight: bold;
            color: var(--env-title-color, $env-title-color);
        }
    }
}

.toolbar {
    flex-grow: 1;
    text-align: right;

    & > .g-button {
        width: 32px;
        height: 32px;
        color: white;
        margin: 0 2px;
        font-size: 0;
        background-color: transparent;
        fill: var(--env-text-color, $env-text-color);
        border: 1px solid var(--env-text-color, $env-text-color);
        position: relative;

        &:hover {
            border-color: var(--success, $success);
            background-color: var(--success, $success);
            fill: var(--btn-primary-color, $btn-primary-color);
        }

        &::after {
            top: 50%;
            line-height: 1;
            padding: 8px;
            transform: translateY(-50%);
            right: calc(100% + var(--tooltip-arrow-size, $tooltip-arrow-size));
        }

        &::before {
            top: 50%;
            transform: translateY(-50%);
            right: calc(100% - var(--tooltip-arrow-size, $tooltip-arrow-size));
            border-left-color: var(--tooltip-bg, $tooltip-bg);
        }
    }
}
</style>